@font-face {
  font-family: "Painter";
  src: url("./assets/fonts/Painter.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
html {
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Nunito", "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
background-image: url(https://firebasestorage.googleapis.com/v0/b/parketlagnir.firebasestorage.app/o/background.png?alt=media&token=f42fa20a-5139-45f9-9705-59c390a31eb2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
