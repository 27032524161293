::-webkit-scrollbar {
  width: 10px;
}

:root {
  --footer-color: #D97706;
  --skyfaze-color: #D97706;
  --logo-color: #d977064a;
  --dashboard-color: #222222;
  --button-menu: #ffffff00;
  --background-color: #F9F5F0;
  --text-color: #131f3a;
  --text-color-main: #1A1A1A;
  --nav-text-color: #3a3a3a;
  --button-color: #f8f8f8;
  --navbar-background-color: #F9F5F0;
  --borders-color: #d9770658;
  --navbar-border-color: transparent;
}
body.dark-theme {
  --footer-color: #D97706;
  --skyfaze-color: #D97706;
  --logo-color: #ffffff9c;
  --dashboard-color: #3e00008e;
  --button-menu: #aaa11f00;
  --text-color-main: #fff;
  --button-color: rgb(255, 255, 255);
  --background-color: #1E1E1E;
  --nav-text-color: #ffffff;
  --text-color: #ffffff;
  --navbar-background-color: #1E1E1E;
  --navbar-border-color: #333;
  --borders-color: #ffffff;
}

.sectiontopdiv {
  margin-bottom: 10%;
}
.buttons {
  color: var(--background-color);
  background-color: var(--text-color-main);
}
.fontweight {
  font-size: 17px;
  font-weight: 500;
}
.fontweight1 {
  font-size: 25px;
  font-weight: 500;
}

.custom-toggler {
  display: none;
}
body {
  overflow: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
}

.videoabout-section {
  margin-top: 10rem;
  margin-bottom: 3rem;
}
.bg-darktransparennt{background-color: var(--logo-color 0);
  border-radius: 0px;
  backdrop-filter: blur(45px);

}

.contact-faqs{margin-top: -6rem!important;}

.faq-item {
  border: 1px solid var(--borders-color);
  border-radius: 0px;
  margin-bottom: 10px;
  color: var(--text-color);
  background-color: #ffffff00;
  transition: background-color 0.3s ease;
}

/* Active item background */
.faq-item:has(.accordion-button:not(.collapsed)) {
  background-color: #1e1e1e00;
}

/* Header button custom styling */
.faq-header .accordion-button {
  font-weight: 600;
  font-size: 1.1rem;
  background-color: #131f3a00;
  border: none;
  box-shadow: none;
  color: var(--text-color);
  transition: color 0.3s ease;
}

/* Remove default bootstrap focus and hover styles */
.faq-header .accordion-button:focus,
.faq-header .accordion-button:hover {
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
}







.testimos{min-height: 4rem;
top: 2rem;}
.HeaderImageabout {
  box-shadow: 0 4px 8px rgba(0, 151, 232, 0.18);
  border: var(--logo-color) 1px solid;
  overflow: hidden;
  border-radius: 1px;
  margin-left: -2.1rem;
  margin-top: 4rem;
}
.videoabout-sectiontext {
  margin-top: 10rem;
  margin-bottom: 3rem;
}
@media (max-width: 990px) {


  .RiveContainerQuote {
    width: 400px;
    height: 250px;
    cursor: pointer;
  margin-left: 0!important;
  margin-top: -3rem!important;
  margin-bottom: -6rem!important;

    padding: 0;
  }
  .shadow {
    margin-bottom: 0rem !important;
    width: auto !important;
  }
  .headerservices {
    margin-bottom: 8rem;
  }
  .servicecardd {
    margin-top: 0 !important;
    scale: 0.9;
  }
 
  .skills {
    margin-bottom: -2rem;
  }
  .c {
    box-shadow: 0 4px 8px rgba(0, 151, 232, 0.18);
    border: var(--logo-color) 2px solid;
    overflow: hidden;
    border-radius: 1px;
    margin-left: 0rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .videoabout-sectiontext {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
  #basic-navbar-nav {
    padding: 1rem;
    z-index: -4;
    margin-top: 1%;
    scale: 0.95;
    color: var(--text-color);
    border-radius: 1px;
    background-color: var(--background-color);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
  .videohomepage {
    scale: 1;
  }
  .HeaderImage {
    margin-top: 3rem;
  }
  .HeaderImageabout {
    margin-top: 4rem;
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .custom-toggler {
    display: block;
    margin-right: 1rem;
    fill: var(--logo-color);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1.2);
  }
}
.section-title {
  font-size: 2.5rem; /* adjust for scale */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  
  text-align: center;
  display: inline-block;
  color: var(--text-color);
  margin-bottom: -2rem;
  padding-bottom: 0.5rem;
}

.section-title::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 3px;
  width: 70px;
  background-color: var(--skyfaze-color, #d97706);
  border-radius: 0;
}
.section-title-mini {
  font-size: 1.5rem; /* adjust for scale */
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  
  text-align: center;
  display: inline-block;
  color: var(--text-color);
  margin-bottom: -2rem;
  padding-bottom: 1rem;
}

.section-title-mini::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 3px;
  width: 8rem;
  background-color: var(--skyfaze-color, #d97706);
  border-radius: 0;
}

.textsmaller {
  font-size: 14px;
}

.profilelogged {
  margin-left: 0rem;
}
.headercall {
  position: relative;
  margin-right: 2rem;
  margin-left: 0;
}

.review-container {
  border: 1px solid #ffffff;
  margin: 1rem;
  border-radius: 1px;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}
.grayscale {
  filter: grayscale(100%);
}
.collection-images {
  box-shadow: 0 4px 8px rgba(0, 151, 232, 0.18);
  border-radius: 0;
  transition: filter 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.collection-images:hover {
  filter: grayscale(70%);
}
.btn-approve {
  align-self: center;
  width: fit-content;
}

.collection-images {
  box-shadow: 0 4px 8px rgba(253, 114, 114, 0.114);
  width: auto;
  height: 200px;
  margin: 1rem;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.collection-images:hover {
  opacity: 0.9;
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.left-button,
.right-button {
  color: var(--background-color);
  background-color: var(--logo-color);
  cursor: pointer;
  transition: transform 0.2s ease;
}

@keyframes wobble-left {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-5px) rotate(-5deg);
  }
  30% {
    transform: translateX(5px) rotate(5deg);
  }
  45% {
    transform: translateX(-5px) rotate(-5deg);
  }
  60% {
    transform: translateX(5px) rotate(5deg);
  }
  75% {
    transform: translateX(-5px) rotate(-5deg);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes wobble-right {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(5px) rotate(5deg);
  }
  30% {
    transform: translateX(-5px) rotate(-5deg);
  }
  45% {
    transform: translateX(5px) rotate(5deg);
  }
  60% {
    transform: translateX(-5px) rotate(-5deg);
  }
  75% {
    transform: translateX(5px) rotate(5deg);
  }
  100% {
    transform: translateX(0);
  }
}

.wobble-left {
  animation: wobble-left 0.5s ease forwards;
}

.wobble-right {
  animation: wobble-right 0.5s ease forwards;
}

.modal {
  transform: scale(1.2);
  max-height: 100vh!important;
  margin-top: 4rem;
  top: 0;
}
@media (max-width: 800px) {
  .secondaryrivcontact {
    display: none;
  }

  .RiveContainerContactForm {
    display: none;
  }

  .modal {
    transform: scale(1);
  }
  .rive-toggle {
    margin-top: -0.6rem;
    margin-left: 0;
  }
  
}
.modal-content {
  
  text-align: center;
min-width: fit-content;

  color: var(--text-color);
  background-color: #ffffff00;
  backdrop-filter: blur(7px);
  border-radius: 12px;
}

.modal-image {
  border-radius: 10px;
  width: auto;
  justify-self: center;
  max-height: 400px!important;
  box-shadow: 0 4px 8px rgba(0, 151, 232, 0.18);

}

.right-button,
.left-button {
  font-weight: 500;
  font-size: 12px;

  scale: 0.9;
  border: solid 1px var(--background-color);

  margin: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 151, 232, 0.18);
}

.custom-nav-container {
  margin: 0;
  display: flex;

  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
}
.custom-nav-ul {
  padding: 0;
  color: var(--nav-text-color);
  display: flex;
  padding-right: 2rem;
}
.custom-nav-li {
  list-style-type: none;
  padding: 10px 20px;
}
.custom-nav-a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: var(--nav-text-color);
  position: relative;
}

.custom-nav-a:before {
  content: "";
  width: 0;
  height: 5px;
  border-radius: 0 0 50px 0;
  background-color: var(--logo-color);
  position: absolute;
  top: 120%;
  left: 0;
  transition: all 0.5s;
}

.custom-nav-a:after {
  content: "";
  width: 0;
  height: 5px;
  border-radius: 0 0 0 50px;
  background-color: var(--nav-text-color);
  position: absolute;
  top: 120%;
  right: 0;
  transition: all 0.5s;
}
.hidden {
  display: none;
}
.custom-nav-a:hover {
  color: var(--nav-text-color);
}

.custom-nav-a:hover:before {
  width: 50%;
  transform: translateX(100%);
}

.custom-nav-a:hover:after {
  width: 50%;
  transform: translateX(-100%);
}

.nav-toggler-burger {
  background-color: var(--button-menu) !important;
}
.rive-toggle {
  margin-top: -0.6rem;
}.RiveWrapper {
  display: flex;
  justify-content: flex-start; /* aligns to left */
  align-items: center;
  width: 100%;
}
.RiveContainerTheme{
  width: 40px;
  height: 40px;
  cursor: pointer;
margin: 1rem;
position: absolute;
  padding: 0;
}

.RiveContainer {
  width: 300px;
  height: 200px;
  cursor: pointer;
margin-left: -3.5rem;
margin-top: -5rem;

  padding: 0;
}
.RiveContainerQuoteService{
  width: 100%;
  height: 200px;
  cursor: pointer;
margin-top: -4rem;
margin-bottom: -3rem;
z-index: 100;

}
.RiveContainerSubmit {
  width: 100%;
  height: 150px;
  cursor: pointer;

margin-top: -2rem;

  padding: 0;
}
.RiveContainerQuote {
  width: 400px;
  height: 250px;
  cursor: pointer;
margin-left: 18rem;
margin-top: -6rem;

  padding: 0;
}
.secondaryrivcontact {
  position: absolute;
  z-index: -1;
  width: 50%;
  height: 100%;
  float: right;
  left: 55%;
  top: 0%;
  scale: 1;
  background-color: none;
}

.RiveContainerContactForm {
  position: fixed;
  z-index: -1;
  width: 50%;
  height: 100%;
  left: -5%;
  top: 0%;
  scale: 1.2;
  background-color: none;
}
.RiveContainerContactWeather {
  position: fixed;
  z-index: -1;
  width: 50%;
  height: 100%;
  left: -5%;
  top: 0%;
  scale: 1.2;
  background-color: none;
}
.logo {
  cursor: pointer;
  margin-left: 46.5vw;
  animation: heartbeat 5s infinite;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05) rotate(-4deg);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.082) rotate(2deg);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.badges {
  animation: heartbeat 3s infinite;
  z-index: 0;
}

.navbarCustom {
  background-color: var(--navbar-background-color);
  border-bottom-color: var(--navbar-border-color);
}

.navbar .navbar-collapse .nav-link.nav-link-custom {
  color: var(--nav-text-color) !important;
}

.zoomed-in {
  transform: scale(1.5);
  cursor: zoom-out;
}

.shadow {
  border-radius: 0;
  background-color: #8498f300;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/parketlagnir.firebasestorage.app/o/background.png?alt=media&token=f42fa20a-5139-45f9-9705-59c390a31eb2);
  color: var(--text-color);
  border: 2px var(--borders-color);
}
.text-dangen {
  color: var(--text-color-main);
}

.nav-toggler-burger {
  background-color: var(--menu-svg);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(134, 132, 132);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--text-color-main);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--text-color-main);
}
.logoutdash {
  margin-top: 8rem;
  margin-left: -1rem;
}
.testpage {
  max-height: 100vh;
  max-width: 100vw;
}

.navbarCustom {
  box-shadow: 0 0 6px var(--logo-color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 300ms ease-in-out;
}
.sticky-top {
  margin-top: 0;
}
.custom-overflow {
  overflow-x: auto;
  overflow-y: hidden;
}
.custom-overflow {
  scroll-behavior: smooth;
}

.custom-overflow ::-webkit-scrollbar,
.custom-review-card ::-webkit-scrollbar {
  display: none;
}

.custom-overflow,
.custom-review-card {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mynavs {
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
}

.mynavs .nav-link {
  color: var(--text-color);
}

.orderTable tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
}
.orderTable thead,
.orderTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.email-cell {
  width: 30%;
}

.service-button {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--text-color-main);
  color: var(--background-color);
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}
.contacthomepage .map-container {
  margin-right: 20px;
}
.mapiner {
  z-index: -2;
}
.contacthomepage .contact-form-container {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .addreviewbutt{scale: 0.9;}
  .contacthomepage .map-container,
  .contacthomepage .contact-form-container {
    margin: 0;
  }
  .mobilelock {
    display: none;
  }
  .logo {
    width: 90px;
    margin-top: 5px;
    height: 70px;

    margin-left: 52vw;
  }
  .nav-link-custom {
    font-weight: 600;
    font-size: larger;
  }

  .navbar .navbar-collapse .nav-link.nav-link-custom {
    color: var(--text-color) !important;

    margin-bottom: 0.3rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.285);
  }
  .headercall {
    margin-left: 0.5rem;

    margin-top: 1rem;
    padding: 0.3rem;
    fill: var(--background-color);
    background-color: var(--logo-color);
    border: 1px solid #00000054;
    border-radius: 50px;
    scale: 1.3;
    float: left;
  }

  .lastlinkmenu {
    margin-bottom: 3rem;
  }
}

.service-button:hover {
  background-color: #2b962b;
  transform: scale(1.05);
}

.about-section {
  margin-top: -2rem;
  margin-bottom: 7rem;
}
.contacthomepage {
  width: 100vw;
}

.marginright {
  margin-right: 2rem;
}
.about-text {
  font-size: 18px;
}
.divider {
  border: 1px solid var(--logo-color);
  opacity: 0.2;
  margin-bottom: 2rem;
  width: 100%;
}
.maincolor {
  color: var(--text-color-main);
}
.videohomepage {
  margin-left: 2rem;
}
.HeaderImage {

  overflow: hidden;
  margin-top: -4rem;

    object-fit: cover;
    width: 100%;
    max-height: 450px;
    height: 100%;
    min-height: 450px; /* Add min-height to reserve space */

}
.HeaderImage:hover {
  box-shadow: 0 2px 4px var(--borders-color);
}

.custom-overflow {
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-overflow::-webkit-scrollbar {
  display: none;
}

.review-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-header img {
  margin-right: 15px;
  border-radius: 50%;
}

.review-header div {
  display: flex;
  flex-direction: column;
}

.review-header h5 {
  margin: 0;
  color: #333;
}

.review-header p {
  margin: 4px 0 0;
  color: #666;
}

.review-body p {
  margin: 0 0 10px;
  color: #555;
}

.review-body div {
  font-weight: bold;
  color: #f4b400;
}

.approve-review-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.approve-review-btn:hover {
  background-color: #45a049;
}

.projectsss {
  z-index: 20;
}

canvas#c {
  margin-top: -100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

video::-webkit-media-controls-panel {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-fullscreen-button {
  display: block !important;
}
video::-webkit-media-controls-download-button {
  display: none !important;
}
.dg.main.a {
  display: none !important;
}
.zfront {
  z-index: 2;
}
.custom-controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.imagereviewdashb {
  padding-right: -2rem;
}
.space {
  height: 8rem;
}
.spaceservices {
  font-size: 1.5rem;
  background-color: none;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.videoabout-section {
  margin-right: 4rem;
  margin-left: -2rem;
}

.paintedtext {
  font-family: Painter;
  left: 50%;
  position: relative;
}

.paintedtext h2 {
  font-size: 3em;
  font-weight: 900;
  position: absolute;
  transform: translate(-50%, -50%);
}

.paintedtext h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px var(--text-color-main);
}

.paintedtext h2:nth-child(2) {
  color: var(--text-color-main);
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
.footercolors {
  background-color: var(--footer-color);
  color: var(--button-color);
}
.waiting {
  cursor: wait !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.contact-icons-container {
  background-color: none;

  padding: 5px;
  border-radius: 8px;
  flex-wrap: wrap;
}
.sercent {
  width: 70%;
  margin: 0 auto; /* Centers the component horizontally */
}

.contact-item {
  color: var(--text-color);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-text {
  font-size: 18px;
  margin-top: 0.9rem;
  margin-left: 10px;
}

.contact-item a {
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.contact-item a:hover {
  text-decoration: underline;
}


@media (min-width: 1298px) {
.videoheader{margin-top: -4rem;
  margin-left: 4rem;}}
@media (max-width: 768px) {
  .sercent {
    width: 100%;
  }
  .contact-icons-container {
    justify-content: flex-start;
    gap: 15px;
  }

  .contact-item {
    width: 100%;
    justify-content: flex-start;
  }

  .contact-text {
    font-size: 16px;
    margin-top: 0.9rem;
  }
}















.skeleton {
  background: #d8eeff;
  border-radius: 4px;
}

.skeleton-text {
  width: 100%;
  height: 1.5em;
  margin-bottom: 10px;
}

.skeleton-paragraph {
  width: 100%;
  height: 4em;
}

.skeleton-button {
  width: 150px;
  height: 40px;
}

.skeleton-media {
  width: 100%;
  height: 450px;
}
