.shadow {
  transition: transform 0.3s ease-in-out; 
  will-change: transform;
}


.service-img {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  max-height: 300px;
  object-fit: cover; 
  padding-left: 0.5rem;
  padding-right: 0.5rem;

}

.shadow:hover .service-img {
  transform: scale(0.97);
}
@media (max-width: 768px) {
  .shadow,
  .service-img {
    transition: none; 
  }

  .shadow:hover,
  .shadow:hover .service-img {
    transform: none;
  }
}